import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.querySelector('.main-content').classList.toggle('expanded', !isOpen);
  };

  const domainUrl = window.location.origin;

  return (
    <div>
      <header className="header">
        <div className='header-content'>
          <div className="header-logo">
            <img src={`${process.env.PUBLIC_URL}/logo1.png`}  alt="Logo" />
            <h1>Real Estate Media Solutions</h1>
          </div>
          <nav className={`header-nav ${isOpen ? 'open' : ''}`}>
            <ul>
              <li><a href={`${domainUrl}/#planbooks`}>Planbooks</a></li>
              <li><a href={`${domainUrl}/#articles`}>Articles</a></li>
              <li><a href={`${domainUrl}/#news`}>News</a></li>
              <li><a href={`${domainUrl}/#contact-us`}>Contact us</a></li>
              <li><a href={`${domainUrl}/#about-us`}>About us</a></li>
            </ul>
          </nav>
          <div className="menu-icon" onClick={toggleMenu}>
            &#9776;
          </div>
        </div>
      </header>
      <div className='header-border-section'>
        <div className='header-border'></div>
      </div>
    </div>
  );
};

export default Header;
