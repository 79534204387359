import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import MainContent from './components/MainContent';
import NewsDetail from './components/NewsDetail';
import ArticleDetail from './components/ArticleDetail';
import Book from './components/Book';

const ScrollToSection = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToSection />
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route path="/articles/:id" element={<ArticleDetail />} />
        <Route path="/book" element={<Book />} />
      </Routes>
    </Router>
  );
}

export default App;
