import React from 'react';
import './Planbook.css';

const Planbook = () => {
  return (
    <section className="planbook-section" id="planbooks">
      <h2>Planbook</h2>
      <div className="planbook-content">
        <div className="planbook-description">
          <h3>What is a Planbook?</h3>
          <p>A planbook is a 3D brochure that developers and brokers can provide to clients, offering an enhanced brochure experience. It showcases properties in an interactive and engaging manner, making it easier for potential buyers to visualize the property details and features.</p>
        </div>
        <div className="planbook-video">
          <video autoPlay loop muted>
            <source src={`${process.env.PUBLIC_URL}/flipbook-video3.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="planbook-benefits">
        <div className="benefit-card">
          <h4>Quick & Sharable Links</h4>
          <p>Easily share your 3D brochures with clients through quick links.</p>
        </div>
        <div className="benefit-card">
          <h4>Update Content Anytime</h4>
          <p>Change the content of your 3D brochures at any time to keep them up-to-date.</p>
        </div>
        <div className="benefit-card">
          <h4>Get Customer Contacts</h4>
          <p>Collect contact information from potential customers seamlessly.</p>
        </div>
        <div className="benefit-card">
          <h4>Enhanced 3D Experience</h4>
          <p>Provide a realistic 3D brochure view for an enhanced customer experience.</p>
        </div>
      </div>
    </section>
  );
};

export default Planbook;
