import React from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './NewsDetail.css';

const news = [
  {
    id: 1,
    title: 'Pune Property Registrations Surge by 60% Year-over-Year, Demand for Homes Above ₹1 Crore Rises',
    content: `
      Pune has seen a significant boost in property registrations, marking a 60% increase year-over-year. The demand for high-end homes, particularly those priced above ₹1 crore, has also surged. Additionally, June 2024 witnessed a remarkable 90% spike in stamp duty collections, further highlighting the robust growth in the real estate sector. This upward trend reflects a strong market sentiment and growing buyer interest in premium properties.
    `,
    reference: 'Hindustan Times',
    image: '1.jpeg',
  },
  {
    id: 2,
    title: 'Google and Anant Raj Cloud Join Forces to Boost India\'s Digital Infrastructure',
    content: `
      Google has announced a partnership with Anant Raj Cloud to bolster India's digital transformation. This collaboration aims to leverage Google's cloud capabilities to enhance the digital infrastructure and drive technological advancements across various sectors in India. This initiative aligns with the government's vision of a digitally empowered society and knowledge economy.

      The partnership is expected to introduce cutting-edge cloud solutions to businesses, helping them scale operations, improve efficiency, and innovate more rapidly. Additionally, it will focus on training and upskilling the local workforce to ensure they are equipped with the necessary skills to thrive in a digital-first environment. This move is anticipated to significantly contribute to India's economic growth and position the country as a leader in the global digital landscape.
    `,
    reference: 'Times Now',
    image: '2.jpeg',
  },
  {
    id: 3,
    title: 'Budget 2024: Real Estate Seeks Vital Boost for Sustained Growth',
    content: `
      The real estate sector is looking forward to the 2024 budget with high expectations, hoping for significant measures to drive long-term growth. Industry stakeholders are calling for reforms and incentives that could provide the much-needed impetus for sustained development. The sector anticipates policies that will enhance affordability, promote investment, and address long-standing challenges, fostering a more robust and resilient real estate market.
    `,
    reference: 'Financial Express',
    image: '3.jpeg',
  },
  {
    id: 4,
    title: 'Real Estate Sector Welcomes Land Record Digitisation Initiative',
    content: `
      The Indian government, as part of Budget 2024, has announced a significant move towards digitising land records across the country. This includes the introduction of a unique identification number for rural land parcels and the use of GIS mapping for urban areas. The initiative aims to enhance transparency, reduce disputes, and streamline land management processes. Industry experts believe this will boost confidence among homebuyers and improve the financial stability of urban local bodies.
    `,
    reference: 'Hindustan Times',
    image: '4.jpeg',
  },
  {
    id: 5,
    title: 'Surge in Private Equity Investments in Indian Real Estate in H1 2024',
    content: `
      Private equity (PE) investments in Indian real estate soared to USD 3.0 billion in the first half of 2024, reflecting a 15% year-on-year increase despite global economic challenges such as high interest rates and geopolitical tensions. This significant rise in investments spanned across office, warehousing, and residential segments. The residential sector saw a remarkable 209% surge in PE investments, totaling USD 854 million, with domestic investors playing a crucial role. Foreign investors maintained a strong presence, accounting for 76% of total investments, while the Middle East emerged as a dominant source of capital. The warehousing sector also gained traction, driven by the e-commerce boom and supply chain needs, surpassing office and retail investments. This trend underscores the resilience and attractiveness of the Indian real estate market amid global uncertainties.
    `,
    reference: 'Knight Frank',
    image: '5.jpeg',
  },
  {
    id: 6,
    title: 'Budget 2024 Changes Property Tax Rules: Winners and Losers Revealed',
    content: `
      The recent Budget 2024 presented by Finance Minister Nirmala Sitharaman has introduced significant changes to property taxation, particularly by removing the indexation benefit on property sales. The long-term capital gain (LTCG) tax rate has been reduced from 20% to 12.5%, effective immediately from July 23, 2024. Analysts from CLSA have weighed in on the implications of this move for different stakeholders in the real estate market.

      Impact on Different Markets:

      Most Affected: High investor activity regions like Mumbai and the National Capital Region (NCR) are expected to feel the most impact due to the loss of indexation benefits.
      Least Affected: End-user driven markets such as Bangalore, Hyderabad, and Pune will likely see minimal impact.
      End-Users vs. Investors:

      End-users who sell their existing homes to reinvest in new properties will see little to no impact.
      Investors selling properties to reinvest in other asset classes will face higher tax outflows due to the removal of indexation benefits.
      Luxury Properties:

      There will be no significant impact on super-luxury apartments valued over Rs 10 crore, as these properties were already subject to capped indexed costs of acquisition from the previous year's budget.
      Industry Reactions:

      Middle-Income Housing: Companies like Sobha, Prestige Estates, Sunteck Realty, and Godrej Properties could benefit from increased allocations to the Pradhan Mantri Awas Yojna (PMAY).
      Overall Real Estate Sector: The removal of indexation benefits is expected to slow down the resale of residential properties and may increase cash transactions in real estate deals, which could be counterproductive.
      Financial Implications:

      Tax Outflows: For example, a property bought for Rs 100 in 2001 and sold for Rs 500 in 2024 would have had a tax outflow of Rs 27.4 under the old regime. Under the new regime, this tax outflow increases to Rs 50 at the new rate of 12.5%.
    `,
    reference: 'Business Standard',
    image: '6.jpeg',
  },
  {
    id: 7,
    title: 'Surge in Land Deals: Bengaluru Leads with 54 Transactions for 1,045 Acres in H1 2024',
    content: `
      In the first half of 2024, India witnessed a significant increase in land transactions with 54 deals covering over 1,045 acres. This marks a notable rise from 46 deals for nearly 950 acres in the same period last year. Bengaluru emerged as the leader, securing nine new land deals for 114 acres in the second quarter of 2024, followed by Gurugram with seven deals for 77.5 acres.

      The transactions spanned various sectors, including residential developments, agriculture, mixed-use developments, data centers, logistics parks, industrial, and retail. Over 17 deals for more than 163 acres were proposed for residential projects alone. This surge reflects the growing demand and development activities in India's real estate market.
    `,
    reference: 'Zee Business',
    image: '7.jpeg',
  },
  {
    id: 8,
    title: 'India\'s Luxury Residential Market Sees Significant Growth',
    content: `
      India's luxury residential market is experiencing a notable surge in demand, driven by urbanization and rising incomes. Sales of luxury housing units, priced at ₹4 crore and above, increased by 27% in the first half of the year, reaching approximately 8,500 units across seven major cities.

      The Indian real estate market, currently valued at around 39 lakh crore, contributes 7.3% to the economic output and is projected to grow to 484 lakh crore by 2047, making up 15.5% of the total economic output. This growth is fueled by a broadening desire for luxurious lifestyles among the expanding middle and upper classes.

      Mumbai and Delhi-NCR lead in luxury housing demand, with Mumbai seeing sales of ₹12,300 crore in homes priced above ₹10 crore in the first half of 2024. Additionally, Pune experienced a significant rise, with luxury housing sales growing nearly sixfold.

      Factors such as the increasing spending power of millennials and the rise of high-net-worth individuals contribute to this trend. The sector has shown resilience despite past challenges like demonetization, GST implementation, and the COVID-19 pandemic, bolstered by policy interventions such as RERA.

      The United Nations World Urbanization Prospects report highlights that rapid urbanization and rising income levels are transforming metropolitan landscapes, further driving the luxury boom.
    `,
    reference: 'CBRE A LEADING REAL ESTATE CONSULTING FIRM',
    image: '8.jpeg',
  },
];

const NewsDetail = () => {
  const { id } = useParams();
  const newsItem = news.find((item) => item.id === parseInt(id, 10));

  if (!newsItem) {
    return <div>News not found</div>;
  }

  return (
    <div className="news-detail-container">
      <Header />
      <div className="news-detail main-content">
        <div className="real-news">
          <img src={`${process.env.PUBLIC_URL}/${newsItem.image}`} alt={newsItem.title} className="news-image" />
          <h2>{newsItem.title}</h2>
          <p>{newsItem.content}</p>
          <p className="reference">Reference: {newsItem.reference}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsDetail;
