import React from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './NewsDetail.css';

const articles = [
  {
    id: 1,
    title: 'Smart Homes in India: A Glimpse into the Future of Living',
    content: `
      The Rise of Smart Homes in India

      The smart home market in India is witnessing a phenomenal transformation, driven by the integration of advanced technologies like the Internet of Things (IoT), Artificial Intelligence (AI), and high-speed internet connectivity. This sector, valued at USD 4 billion in 2024, is expected to soar to USD 14.28 billion by 2029, growing at an impressive compound annual growth rate (CAGR) of 30%.

      Key Drivers of Growth

      Technological Advancements: The proliferation of AI and IoT-enabled devices is a significant driver, enhancing the automation and user experience within homes. Innovations such as smart lighting, thermostats, and security systems are not only making homes more convenient but also more energy-efficient and secure.

      Consumer Demand for Security: A major motivation for Indian consumers to adopt smart home technologies is enhanced security. Approximately 50% of potential smart home buyers are attracted by the advanced security features, including AI-powered cameras and smart locks that offer real-time monitoring and keyless access.

      Energy Efficiency: With increasing awareness about environmental sustainability, energy management has become a crucial aspect of smart homes. Devices like smart thermostats and energy-efficient lighting systems help in optimizing energy usage, thus reducing overall consumption and contributing to a greener environment.

      Market Segments and Trends

      Security and Access Management: This segment is poised to dominate the market, projected to grow at a CAGR of 26.6% by 2034. The adoption of AI-driven security solutions provides robust and customized protection for homes, significantly reducing false alarms and enhancing overall safety.

      Smart Lighting and Climate Control: Companies are heavily investing in smart lighting and HVAC systems that not only provide comfort but also contribute to substantial energy savings. For instance, Signify Innovations India aims to establish 200 Philips Smart Light Hubs across the country, reinforcing the growing market for smart lighting.

      Challenges and Future Outlook

      Despite the rapid growth, the smart home market in India faces challenges such as high costs, complexity in installation, and concerns over data privacy and cybersecurity. However, the continuous advancements in technology and increasing consumer awareness are expected to mitigate these issues over time.

      Conclusion

      The future of smart homes in India looks promising, with significant growth driven by technological innovations and consumer demand for security and energy efficiency. As more households adopt these advanced systems, the smart home market is set to revolutionize the way Indians live, providing unprecedented levels of comfort, security, and sustainability.
    `,
    reference: 'Mordor Intelligence, Future Market Insights, Statista',
    image: 'article1.jpeg',
  },
  {
    id: 2,
    title: 'REITs in India: Revolutionizing Real Estate Investment',
    content: `
      Introduction to REITs in India

      Real Estate Investment Trusts (REITs) have transformed the landscape of real estate investment in India since their inception in 2019. Modeled after successful global REIT structures, they offer a way for individual and institutional investors to gain exposure to real estate without direct property ownership. REITs pool funds from numerous investors to invest in income-generating real estate assets, distributing 90% of net distributable income as dividends, providing both income and capital appreciation.

      Market Growth and Performance

      The Indian REIT market has shown significant growth, with notable distributions and capital raisings. In five years, REITs in India distributed over Rs 15,500 crore and raised Rs 25,500 crore through primary issuances. With over 200,000 unitholders, the popularity of REITs among retail investors is evident. As of 2024, there are four listed REITs in India: Embassy Office Parks REIT, Mindspace Business Parks REIT, Brookfield India REIT, and Nexus Select Trust.

      Investment Opportunities and Benefits

      Investing in REITs offers several advantages:

      Accessibility and Liquidity: REITs provide a way for small investors to own a stake in high-value commercial real estate, with units that can be easily traded on stock exchanges, ensuring liquidity.
      Diversification: REITs allow investors to diversify their portfolios across different real estate assets, including office spaces, retail centers, and more.
      Stable Income: With mandatory dividend distributions, REITs offer a stable income stream, making them attractive for income-focused investors.
      Transparency and Regulation: Regulated by SEBI, REITs in India adhere to strict standards, ensuring transparency and investor protection.

      Emerging Trends and Future Prospects

      The future of REITs in India looks promising with several trends:

      Micro, Small, and Medium REITs (MSM REITs): These have been introduced to allow smaller investments, with a minimum asset size of Rs 25 crore, making REITs more accessible to a broader range of investors. They focus on transparency and niche-targeted investments, enhancing investor control and customization.
      Increasing Retail Investor Participation: With the minimum ticket size of Rs 10 lakhs, MSM REITs are designed to attract retail investors, democratizing real estate investments.
      Expanding Market: India has substantial REIT-able office and retail space, signaling significant growth potential. Currently, only a fraction of the available space is included in existing REITs, suggesting ample room for new listings and expansion.

      Conclusion

      REITs have revolutionized real estate investment in India by making high-quality commercial properties accessible to everyday investors. With ongoing regulatory support, increasing market awareness, and emerging trends like MSM REITs, the sector is poised for robust growth, promising stable returns and enhanced investment opportunities for a diverse investor base.
    `,
    reference: 'Stable Investor, Housing.com, Home Bazaar, Mint',
    image: 'article2.jpeg',
  },
];

const ArticleDetail = () => {
  const { id } = useParams();
  const article = articles.find((item) => item.id === parseInt(id, 10));

  if (!article) {
    return <div>Article not found</div>;
  }

  return (
    <div className="article-detail-container">
      <Header />
      <div className="article-detail">
        <div className="real-article">
          <img src={`${process.env.PUBLIC_URL}/${article.image}`} alt={article.title} className="article-image" />
          <h2>{article.title}</h2>
          <p>{article.content}</p>
          <p className="reference">Reference: {article.reference}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ArticleDetail;
