import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './MainContent.css';
import Planbook from './Planbook';
import emailjs from 'emailjs-com';

const news = [
  {
    id: 1,
    title: 'Pune Property Registrations Surge by 60% Year-over-Year, Demand for Homes Above ₹1 Crore Rises',
    content: 'Pune has seen a significant boost in property registrations, marking a 60% increase year-over-year. The demand for high-end homes, particularly those priced above ₹1 crore, has also surged. Additionally, June 2024 witnessed a remarkable 90% spike in stamp duty collections, further highlighting the robust growth in the real estate sector. This upward trend reflects a strong market sentiment and growing buyer interest in premium properties.',
    reference: 'Hindustan Times',
  },
  {
    id: 2,
    title: 'Google and Anant Raj Cloud Join Forces to Boost India\'s Digital Infrastructure',
    content: 'Google has announced a partnership with Anant Raj Cloud to bolster India\'s digital transformation. This collaboration aims to leverage Google\'s cloud capabilities to enhance the digital infrastructure and drive technological advancements across various sectors in India. This initiative aligns with the government\'s vision of a digitally empowered society and knowledge economy.',
    reference: 'Times Now',
  },
  {
    id: 3,
    title: 'Budget 2024: Real Estate Seeks Vital Boost for Sustained Growth',
    content: 'The real estate sector is looking forward to the 2024 budget with high expectations, hoping for significant measures to drive long-term growth. Industry stakeholders are calling for reforms and incentives that could provide the much-needed impetus for sustained development. The sector anticipates policies that will enhance affordability, promote investment, and address long-standing challenges, fostering a more robust and resilient real estate market.',
    reference: 'Financial Express',
  },
  {
    id: 4,
    title: 'Real Estate Sector Welcomes Land Record Digitisation Initiative',
    content: 'The Indian government, as part of Budget 2024, has announced a significant move towards digitising land records across the country. This includes the introduction of a unique identification number for rural land parcels and the use of GIS mapping for urban areas. The initiative aims to enhance transparency, reduce disputes, and streamline land management processes. Industry experts believe this will boost confidence among homebuyers and improve the financial stability of urban local bodies.',
    reference: 'Hindustan Times',
  },
  {
    id: 5,
    title: 'Surge in Private Equity Investments in Indian Real Estate in H1 2024',
    content: 'Private equity (PE) investments in Indian real estate soared to USD 3.0 billion in the first half of 2024, reflecting a 15% year-on-year increase despite global economic challenges such as high interest rates and geopolitical tensions. This significant rise in investments spanned across office, warehousing, and residential segments. The residential sector saw a remarkable 209% surge in PE investments, totaling USD 854 million, with domestic investors playing a crucial role.',
    reference: 'Knight Frank',
  },
  {
    id: 6,
    title: 'Budget 2024 Changes Property Tax Rules: Winners and Losers Revealed',
    content: 'The recent Budget 2024 presented by Finance Minister Nirmala Sitharaman has introduced significant changes to property taxation, particularly by removing the indexation benefit on property sales. The long-term capital gain (LTCG) tax rate has been reduced from 20% to 12.5%, effective immediately from July 23, 2024. Analysts from CLSA have weighed in on the implications of this move for different stakeholders in the real estate market.',
    reference: 'Business Standard',
  },
  {
    id: 7,
    title: 'Surge in Land Deals: Bengaluru Leads with 54 Transactions for 1,045 Acres in H1 2024',
    content: 'In the first half of 2024, India witnessed a significant increase in land transactions with 54 deals covering over 1,045 acres. This marks a notable rise from 46 deals for nearly 950 acres in the same period last year. Bengaluru emerged as the leader, securing nine new land deals for 114 acres in the second quarter of 2024, followed by Gurugram with seven deals for 77.5 acres.',
    reference: 'Zee Business',
  },
  {
    id: 8,
    title: 'India\'s Luxury Residential Market Sees Significant Growth',
    content: 'India\'s luxury residential market is experiencing a notable surge in demand, driven by urbanization and rising incomes. Sales of luxury housing units, priced at ₹4 crore and above, increased by 27% in the first half of the year, reaching approximately 8,500 units across seven major cities.',
    reference: 'CBRE A LEADING REAL ESTATE CONSULTING FIRM',
  },
];

const articles = [
  {
    id: 1,
    title: 'Smart Homes in India: A Glimpse into the Future of Living',
    content: 'The smart home market in India is witnessing a phenomenal transformation, driven by the integration of advanced technologies like the Internet of Things (IoT), Artificial Intelligence (AI), and high-speed internet connectivity. This sector, valued at USD 4 billion in 2024, is expected to soar to USD 14.28 billion by 2029, growing at an impressive compound annual growth rate (CAGR) of 30%.',
    reference: 'Mordor Intelligence',
  },
  {
    id: 2,
    title: 'REITs in India: Revolutionizing Real Estate Investment',
    content: 'Real Estate Investment Trusts (REITs) have transformed the landscape of real estate investment in India since their inception in 2019. Modeled after successful global REIT structures, they offer a way for individual and institutional investors to gain exposure to real estate without direct property ownership.',
    reference: 'Stable Investor',
  },
];

const MainContent = () => {

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_85gmiva', 'template_gz1crt6', e.target, 'OubKQiCY9xfqIiA2A')
      .then((result) => {
          console.log(result.text);
          alert("Message sent successfully!");
      }, (error) => {
          console.log(error.text);
          alert("Failed to send the message. Please try again.");
      });

    e.target.reset();
  };

  return (
    <div className="main-content-container">
      <Header />
      <div className="main-content">
      <Planbook />
        <section className="news-section" id="news">
          <h2>Latest News</h2>
          <div className="news-grid">
            {news.map((item, index) => (
              <div key={index} className="news-card">
                <h3>{item.title}</h3>
                <p>{item.content}</p>
                <Link to={`/news/${item.id}`} className="read-more-link">Read More</Link>
                <p className="reference">Reference: {item.reference}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="articles-section" id="articles">
          <h2>Latest Articles</h2>
          <div className="articles-grid">
            {articles.map((item, index) => (
              <div key={index} className="article-card">
                <h3>{item.title}</h3>
                <p>{item.content}</p>
                <Link to={`/articles/${item.id}`} className="read-more-link">Read More</Link>
                <p className="reference">Reference: {item.reference}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="about-us-section" id="about-us">
          <h2>About Us</h2>
          <p>We are Real Estate Media Solutions, a company dedicated to providing high-quality content, 3D brochures, and other innovative solutions for the real estate industry. Our goal is to enhance the experience of developers, brokers, and buyers through cutting-edge technology and exceptional service.</p>
        </section>
        <section className="contact-us-section" id="contact-us">
          <h2>Contact Us</h2>
          <p>Mobile: +91 1234567890</p>
          <form className="contact-form" onSubmit={sendEmail}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="5" required></textarea>
            </div>
            <button type="submit" className="submit-button">Send Message</button>
          </form>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default MainContent;
