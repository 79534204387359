import React from 'react';
import './Footer.css';

const Footer = () => {
  const domainUrl = window.location.origin;

  return (
    <footer className="footer">
      <div className="footer-left">
        <p>Address: TheRealMine, 3rd floor, Shrenik Garden Society, Krave Nagar, Pune 410052</p>
      </div>
      <div className="footer-center">
        <h3>Explore</h3>
        <ul>
              <li><a href={`${domainUrl}/#planbooks`}>Planbooks</a></li>
              <li><a href={`${domainUrl}/#articles`}>Articles</a></li>
              <li><a href={`${domainUrl}/#news`}>News</a></li>
              <li><a href={`${domainUrl}/#contact-us`}>Contact us</a></li>
              <li><a href={`${domainUrl}/#about-us`}>About us</a></li>
        </ul>
      </div>
      <div className="footer-right">
        <h3>Follow</h3>
        <ul>
          <li><a href="https://www.instagram.com/_the_real_mine_">Instagram</a></li>
          <li><a href="#facebook">Facebook</a></li>
          <li><a href="#linkedin">LinkedIn</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
