import React from 'react';
import './Planbook.css';

const Book = () => {
  return (
    <iframe src="https://dearflip.com/pdf-viewer" width="100%" height="100%"></iframe>
  );
};

export default Book;
